.Form {
  display: grid;
  position: relative;
  z-index: 0;
  /* max-width: 550px; */
  grid-template-columns: minmax(0, 1fr);
  grid-auto-flow: row;
  gap: 16px;
  margin: 25px 50px 25px 50px;
  background: rgba(30, 30, 30, 1);
  padding-bottom: 20px;
}

.Submit-button {
  margin: 20px 20px 20px auto;
  display: flex;
  border: 1px rgba(250, 250, 250, 1) solid;
  float: right;
  padding: 10px 40px;
  height: 40px;
  min-width: 65px;
  width: fit-content;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  justify-content: center;
  align-items: center;
  background: rgba(211, 211, 211, 0.2);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#7e7e7e', endColorstr='#737373',GradientType=0 ); /* IE6-8 */
  font-size: 16px;
  font-weight: bold;
  color: rgba(255, 255, 255, 1);
  font-family: inherit;
  box-shadow: none;
}
.Submit-button.Submit-button--active,
.Submit-button.Submit-button--active:hover {
  background: rgba(255, 255, 255, 1);
  color: rgb(0, 0, 0, 0.7);
  font-weight: 900;
}

.Submit-button img {
  width: 25px;
  height: 25px;
}

.Submit-button:hover {
  background: rgba(211, 211, 211, 0.4);
}

.Form-header {
  margin-left: 16px;
  font-family: inherit;
}

.Form-header span {
  padding-left: 16px;
  color: rgba(250, 250, 250, 1);
}

.Form-header object {
  position: relative;
  top: 7px;
}

.Size-fields {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
}
