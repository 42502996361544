.Scrollbutton-wrapper {
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 99;
  background-color: rgba(30, 30, 30, 1);
  border-radius: 30px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}

.Scrollbutton {
  cursor: pointer;
  border-radius: 30px;
  background-color: rgba(211, 211, 211, 0.2);
  border: none;
  width: 44px;
  height: 44px;
}
