.Portfolio {
  overflow: hidden;
  position: relative;
  height: 100%;
  background: rgba(30, 30, 30, 1);
  border-color: #4b5563;
  width: 100%;
}

.Portfolio-track {
  display: flex;
  position: absolute;
  flex-flow: row wrap;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  left: 0;
  align-content: flex-start;
  width: 500%;
  background: rgb(41, 40, 40);
  justify-content: flex-start;
  gap: 16px;
  /* animation: slide 20s linear infinite; */
}

.portfolio-card {
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
  background: rgb(41, 40, 40);
}

.portfolio-card > img {
  height: 100%;
  width: 100%;
}

@keyframes slide {
  0% {
    left: 0;
  }
  100% {
    left: -250%;
  }
}
