.Checkbox {
  position: relative;
  display: flex;
  flex-direction: column;
  vertical-align: baseline;
  height: fit-content;
  margin: 0px 15px 0px 15px;
}

.Checkbox-wrapper {
  display: flex;
  align-items: center;
  position: relative;
  flex-direction: row;
  border-left: 2px solid rgba(250, 250, 250, 1);
  padding-left: 14px;
}

.Checkbox-label {
  padding-left: 8px;
  margin: 0;
  margin-bottom: 4px;
  font-family: inherit;
  color: rgba(250, 250, 250, 1);
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  display: block;
  height: 21px;
  position: relative;
}

.error {
}

.error-message {
}
