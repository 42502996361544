.card {
  display: grid;
  grid-template-columns: fit-content(25ch) fit-content(8ch);
  width: auto;
  gap: 25px;
  width: fit-content;
}

.card > div:last-child {
  margin: auto;
}

.card-body {
  font-family: inherit;
}

.card-body > p,
.card-body > h4,
.card-body > a {
  color: rgba(250, 250, 250, 1);
  text-decoration: none;
  white-space: pre-line;
}

.card-image {
  height: 90px;
  width: 90px;
}

.card-image > img {
  width: 100%;
  height: 100%;
}
