.NavBackground {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  background: rgba(10, 10, 10, 0.8);
}

.NavBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fffff0;
  margin: 5px 60px;
  z-index: 999;
}

.LogoText {
  word-spacing: 100vw;
  font-size: 18px;
  font-weight: 400;
  margin: 10px 0px;
}

.Mirrored {
  display: inline-block;
  -moz-transform: scale(-1, 1);
  -webkit-transform: scale(-1, 1);
  -o-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  transform: scale(-1, 1);
}

.NavMenu {
  display: flex;
  align-items: center;
  list-style: none;
  font-size: 14px;
  gap: 15px;
}

.NavItem {
  font-weight: 300;
  display: inline-block;
  position: relative;
  cursor: pointer;
}

.NavItem::after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 1px;
  bottom: 0;
  left: 0;
  background-color: #ffffff;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.NavItem:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.active {
  visibility: visible;
  transition: all 0.5s;
}

.hidden {
  visibility: hidden;
  transition: all 0.5s;
  transform: translateY(-100%);
}
