.Textarea {
  position: relative;
  display: flex;
  flex-direction: column;
  vertical-align: baseline;
  height: fit-content;
  margin: 0px 15px 0px 15px;
}

.Textarea-input-wrapper {
  display: flex;
  align-items: center;
  position: relative;
}

.Textarea-label {
  padding-left: 14px;
  margin: 0;
  margin-bottom: 4px;
  border-left: 2px solid rgba(250, 250, 250, 1);
  font-family: inherit;
  color: rgba(250, 250, 250, 1);
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  display: block;
  height: 21px;
  position: relative;
}

.Textarea-input {
  width: 100%;
  height: 75px;
  resize: none;
  line-height: 1.5;
  padding: 0 0 0 16px;
  margin: 0;
  border: rgba(211, 211, 211, 0.2);
  background: rgba(211, 211, 211, 0.2);
  box-shadow: none;
  border-radius: 3px;
  font-size: 18px;
  color: rgba(250, 250, 250, 1);
  font-family: inherit;
  box-sizing: border-box;
}

.Textarea-input:focus {
  background: rgba(211, 211, 211, 0.4);
  border: none;
  outline: none;
}

.error,
.error-message:focus {
  border: rgba(255, 0, 0, 1) solid 1px;
}
.error-message {
  margin-right: auto;
  font-size: 14px;
  color: rgba(255, 0, 0, 1);
}
