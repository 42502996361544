/* HTML: <div class="loader"></div> */
.Loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5); /* Slight overlay */
  z-index: 1050; /* Ensure it's above other elements */
  transition: opacity 0.3s ease-in-out;
}

.hidden {
  display: none !important;
  opacity: 0;
  pointer-events: none;
}

.modal-dialog {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 300px;
}

.modal-content {
  background-color: rgba(50, 50, 50, 1);
  border: none;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.modal-body {
  padding: 20px 40px;
}

.text-center {
  text-align: center;
}

.loading-spinner {
  width: 40px;
  margin: auto;
  aspect-ratio: 1;
  display: grid;
  grid: 50%/50%;
  color: rgba(250, 250, 250, 1);
  --_g: no-repeat linear-gradient(currentColor 0 0);
  background: var(--_g), var(--_g), var(--_g);
  background-size: 50.1% 50.1%;
  animation: l6-0 1.5s infinite steps(1) alternate, l6-0-0 3s infinite steps(1);
}
.loading-spinner::before {
  content: '';
  background: currentColor;
  transform: perspective(150px) rotateY(0deg) rotateX(0deg);
  transform-origin: bottom right;
  animation: l6-1 1.5s infinite linear alternate;
}
.loader-message {
  color: rgba(250, 250, 250, 1);
}
.loader-message__padding {
  padding-top: 25px;
}

@keyframes l6-0 {
  0% {
    background-position: 0 100%, 100% 100%, 100% 0;
  }
  33% {
    background-position: 100% 100%, 100% 100%, 100% 0;
  }
  66% {
    background-position: 100% 0, 100% 0, 100% 0;
  }
}
@keyframes l6-0-0 {
  0% {
    transform: scaleX(1) rotate(0deg);
  }
  50% {
    transform: scaleX(-1) rotate(-90deg);
  }
}
@keyframes l6-1 {
  16.5% {
    transform: perspective(150px) rotateX(-90deg) rotateY(0deg) rotateX(0deg);
    filter: grayscale(1);
  }
  33% {
    transform: perspective(150px) rotateX(-180deg) rotateY(0deg) rotateX(0deg);
  }
  66% {
    transform: perspective(150px) rotateX(-180deg) rotateY(-180deg)
      rotateX(0deg);
  }
  100% {
    transform: perspective(150px) rotateX(-180deg) rotateY(-180deg)
      rotateX(-180deg);
    filter: grayscale(1);
  }
}
