.background {
  height: 100%;
  width: 100%;
  float: left;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  padding: none;
  /* position: fixed; */
  z-index: -1;
}

@media (max-width: 1079px) {
  .background {
    object-fit: contain; /* Changed from 'cover' to 'contain' to ensure the entire image is visible */
  }
}
