.Textfield {
  position: relative;
  display: flex;
  flex-direction: column;
  vertical-align: baseline;
  height: fit-content;
  margin: 0px 15px 0px 15px;
}

.Textfield-input-wrapper {
  display: flex;
  align-items: center;
  position: relative;
  flex-direction: column;
}

.Textfield-input-wrapper__unit {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.Textfield-label {
  padding-left: 14px;
  margin: 0;
  margin-bottom: 4px;
  border-left: 2px solid rgba(250, 250, 250, 1);
  font-family: inherit;
  color: rgba(250, 250, 250, 1);
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  display: block;
  height: 21px;
  position: relative;
}

.Textfield-input,
.Textfield-input__unit-value {
  width: 100%;
  height: 36px;
  line-height: 1.5;
  padding: 0 0 0 16px;
  margin: 0;
  border: rgba(211, 211, 211, 0.2);
  background: rgba(211, 211, 211, 0.2);
  box-shadow: none;
  border-radius: 3px;
  font-size: 18px;
  color: rgba(250, 250, 250, 1);
  font-family: inherit;
  box-sizing: border-box;
}

.Textfield-input__unit-value {
  padding-right: 10px;
}

.Textfield-input:focus,
.Textfield-input__unit-value:focus {
  background: rgba(211, 211, 211, 0.4);
  border: none;
  outline: none;
}

.Textfield-input__unit-value {
  flex: 1;
  margin-right: 10px;
}

.Textfield-input input ::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: none;
  text-align: right;
}

.Textfield-input-unit {
  padding-top: 10px;
  font-family: inherit;
  color: rgba(250, 250, 250, 1);
}

.error,
.error:focus {
  border: rgba(255, 0, 0, 1) solid 1px;
}
.error-message {
  margin-right: auto;
  font-size: 14px;
  color: rgba(255, 0, 0, 1);
}
