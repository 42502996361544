.burgerToggle {
  display: block;
  position: fixed;
  top: 20px;
  right: 20px;

  z-index: 1;

  -webkit-user-select: none;
  user-select: none;
}

.burgerToggle input {
  display: block;
  width: 40px;
  height: 32px;
  position: fixed;
  top: 20px;
  right: 20px;

  cursor: pointer;

  opacity: 0; /* hide this */
  z-index: 2; /* and place it over the hamburger */

  -webkit-touch-callout: none;
}

/*
  * Just a quick hamburger
  */
.burgerToggle span {
  display: block;
  width: 33px;
  height: 4px;
  margin-bottom: 5px;
  position: relative;

  background: #fff;
  border-radius: 3px;

  z-index: 1;

  transform-origin: 4px 0px;

  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
    background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
}

.burgerToggle span:first-child {
  transform-origin: 0% 0%;
}

.burgerToggle span:nth-last-child(2) {
  transform-origin: 0% 100%;
}

.burgerToggle .open ~ span {
  opacity: 1;
  transform: rotate(45deg) translate(-2px, -1px);
  background: #fffff0;
}

.burgerToggle .open ~ span:nth-last-child(3) {
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}

.burgerToggle .open ~ span:nth-last-child(2) {
  opacity: 1;
  transform: rotate(-45deg) translate(0, -1px);
}

.navMenu {
  position: fixed;
  width: 300px;
  margin: -100px 0 0 0;
  padding: 50px;
  padding-top: 125px;
  right: -100px;
  height: 100vh;

  background: rgba(10, 10, 10, 0.8);
  list-style-type: none;
  -webkit-font-smoothing: antialiased;
  /* to stop flickering of text in safari */

  transform-origin: 0% 0%;
  transform: translate(100%, 0);

  transition: right 1s;
}

.navMenu .navItem {
  color: #fffff0;
  font-family: inherit;
}

.closed {
  display: none;
}

.navMenu li {
  padding: 10px 0;
  font-size: 22px;
}

/*
  * And let's fade it in from the left
  */
.burgerToggle .open ~ ul {
  transform: none;
  opacity: 1;
}

@media screen and (max-width: 768px) {
  .navMenu {
    transform: none;
    opacity: 0;

    transition: opacity 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
  }
}
