.FileInput {
  position: relative;
  display: flex;
  flex-direction: column;
  vertical-align: baseline;
  height: fit-content;
  margin: 0px 15px 0px 15px;
}

.FileInput-input-wrapper {
  display: flex;
  align-items: center;
  position: relative;
  flex-direction: column;
  background: rgba(211, 211, 211, 0.2);
}

.FileInput-input-wrapper__unit {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.FileInput-label {
  padding-left: 14px;
  margin: 0;
  margin-bottom: 4px;
  border-left: 2px solid rgba(250, 250, 250, 1);
  font-family: inherit;
  color: rgba(250, 250, 250, 1);
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  display: block;
  height: 21px;
  position: relative;
}

.FileInput-input {
  display: none;
}

.FileInput-input__drag-n-drop-container {
  display: flex;
  cursor: pointer;
  border: rgba(211, 211, 211, 0.2);
  width: 100%;
  margin: 0 0 0 16px;
  margin: 0;
  border: rgba(211, 211, 211, 0.2);
  box-shadow: none;
  border-radius: 3px;
}

.FileInput-heading__wrapper {
  margin: 5px;
  border: 2px dashed rgba(211, 211, 211, 0.6);
  width: 100%;
  /* padding: 16px; */
}

.FileInput-heading__text {
  display: grid;
  margin: 16px 0;
  gap: 10px;
}

.FileInput-heading__paragraph {
  font-size: 16px;
  color: rgba(211, 211, 211, 0.6);
  font-weight: 600;
}

.FileInput-heading__alternative {
  font-size: 13px;
  color: rgba(211, 211, 211, 0.4);
  margin: 0 0 0 0;
}

.FileInput-heading__paragraph,
.FileInput-heading__alternative {
  margin: 0;
  text-align: center;
  vertical-align: middle;
}

.FileInput-heading__icon {
  display: block;
  margin: auto;
  color: rgba(211, 211, 211, 0.6);
  height: 17px !important;
  width: 17px !important;
}

.FileInput-heading__alternative .FileInput-input-unit {
  padding-top: 10px;
  font-family: inherit;
  color: rgba(250, 250, 250, 1);
}

.FileInput-preview {
  display: flex;
  flex-wrap: wrap;
  padding-left: 5px;
  width: 100%;
}

.error,
.error:focus {
  border: rgba(255, 0, 0, 1) solid 1px;
}

.error-message {
  margin-right: auto;
  font-size: 14px;
  color: rgba(255, 0, 0, 1);
}
