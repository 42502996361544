.Togglegroup {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0 16px 0 16px;
}

.Togglegroup-label {
  padding-left: 14px;
  margin: 0;
  margin-bottom: 4px;
  border-left: 2px solid rgba(250, 250, 250, 1);
  font-family: inherit;
  color: rgba(250, 250, 250, 1);
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  display: block;
  height: 21px;
  position: relative;
}

.Togglegroup-wrapper {
  display: flex;
  position: relative;
  flex-wrap: wrap;
}

.Togglegroup-option {
  float: left;
  flex: 1;
  padding: 10px;
  margin: 0;
  height: 40px;
  min-width: 65px;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  background: rgba(211, 211, 211, 0.2);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#7e7e7e', endColorstr='#737373',GradientType=0 ); /* IE6-8 */
  font-size: 13px;
  font-weight: bold;
  color: rgba(255, 255, 255, 1);
  border: none;
  font-family: inherit;
  box-shadow: none;
  border: none;
}

.Togglegroup-wrapper .Togglegroup-option-three {
  flex: 1 1 33.3%;
  /*Start Run Code Snippet output CSS*/
  padding: 5px;
  box-sizing: border-box;
  text-align: center;
  /*End Run Code Snippet output CSS*/
}

.Togglegroup-wrapper .Togglegroup-option-four {
  flex: 1 1 25%;
  /*Start Run Code Snippet output CSS*/
  padding: 5px;
  box-sizing: border-box;
  text-align: center;
  /*End Run Code Snippet output CSS*/
}

.Togglegroup-option.Togglegroup-option--active,
.Togglegroup-option.Togglegroup-option--active:hover {
  background: rgba(255, 255, 255, 1);
  color: rgb(0, 0, 0, 0.7);
  font-weight: 900;
}

.Togglegroup-option:hover {
  background: rgba(211, 211, 211, 0.4);
}

.expandableText {
  flex: 1 1 100%;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out, visibility 0s 0.5s;
  visibility: hidden;
}

.expanded {
  max-height: 500px; /* Set to a value that exceeds the content height */
  visibility: visible;
  transition: max-height 0.5s ease-out, visibility 0s 0s;
}

.expandableText div {
  margin: 0px !important;
}

.expandableText div label {
  border-left: none;
  padding-left: 5px;
}
