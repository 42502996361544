.Thumbnail {
  position: relative;
  flex: 0 1 33.3%;
  max-width: 110px;
  padding: 5px;
  box-sizing: border-box;
}

.Thumbnail-wrapper {
  position: relative;
  max-width: 100px;
  aspect-ratio: 1;
  overflow: hidden;
}

.Thumbnail-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.Thumbnail-remove {
  z-index: 1;
  background: rgb(30, 30, 30);
  padding: 2px;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  position: absolute;
  top: -3px;
  right: -4px;
  box-shadow: -1px 1px 2px rgb(69, 69, 69);
}

.Thumbnail-remove__icon {
  cursor: pointer;
  height: 20px;
  width: 20px;
  color: rgb(250, 250, 250);
}

.Thumbnail-size {
  z-index: 1;
  background: rgb(30, 30, 30);
  padding: 0 5px;
  height: fit-content;
  width: fit-content;
  position: absolute;
  bottom: 3px;
  left: -2px;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 10px;
  box-shadow: -1px 1px 2px rgb(69, 69, 69);
}

.Thumbnail-size__text {
  font-size: 12px;
  color: rgb(250, 250, 250);
}
