.bookings-view {
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 2fr);
  background: rgba(30, 30, 30, 1);
  /* height: 100%; */
}

.Form-wrapper {
  position: relative;
}

.Form-wrapper::after {
  content: '';
  position: absolute;
  top: 5%;
  right: 0;
  bottom: 5%;
  width: 1px;
  background-color: rgba(250, 250, 250, 1);
}

@media only screen and (max-width: 1248px) {
  .bookings-view {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media only screen and (min-width: 1249px) {
  .bookings-view {
    grid-template-columns: minmax(0, 1fr) minmax(0, 2fr);
  }
}

@media only screen and (max-width: 715px) {
  .bookings-view {
    grid-template-columns: minmax(0, 1fr);
  }
  .Form-wrapper {
    order: 2;
  }
}

.Terms-and-conditions-wrapper {
  font-family: inherit;
  color: white;
  padding: 25px 50px;
}

.Terms-and-conditions__header {
  margin-top: 25px;
}

.Terms-and-conditions__body p {
  line-height: 1.6;
}

.Terms-and-conditions__body ul {
  padding-left: 25px;
}

.Terms-and-conditions__body ul li:not(:last-child) {
  margin-bottom: 8px;
}
