.App section {
  background-size: cover;
  /* height: 100vh; */
  display: grid;
  grid-template-columns: 1;
}

/* @media only screen and (max-width: 715px) {
  .App section {

  }
} */

@media only screen and (min-width: 716px) {
  .App section {
    /* height: 100vh; */
  }
}

.App section .Section-wrapper {
  background-color: black;
}

a,
a:link,
a:visited,
a:focus,
a:hover,
a:active {
  color: rgba(250, 250, 250, 1);
  text-decoration: none;
}
.klimentyv-icon {
  color: #fafafa;
  width: 25px;
  height: 25px;
}
