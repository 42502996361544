.footer-view {
  display: grid;
  position: relative;
  grid-auto-rows: auto;
  background-color: rgb(25, 25, 25);
}

@media (max-width: 576px) {
  .footer-view {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  }
}

@media screen and (min-width: 577px) {
  .footer-view {
    grid-template-columns: minmax(0, 1fr) fit-content(8ch) minmax(0, 1fr);
  }
}

.footer-view::before {
  content: ''; /* Required for the pseudo-element to work */
  position: absolute;
  top: 15%;
  left: 5%; /* Adjust this value to increase/decrease the clear space on the left */
  right: 5%; /* Adjust this value to increase/decrease the clear space on the right */
  height: 1px; /* Thickness of the border */
  background-color: rgba(250, 250, 250, 0.5);
}

.footer-view > div {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .footer-view > div:first-child, */
@media screen and (max-width: 576px) {
  .footer-view > div:nth-child(-n + 2) {
    padding: 75px 0px 45px 0px;
  }
  .footer-view > div:first-child,
  .footer-view > div:nth-child(2) {
    flex-direction: column;
    gap: 16px;
  }
}
@media screen and (min-width: 577px) {
  .footer-view > div:nth-child(-n + 3) {
    padding: 75px 0px 45px 0px;
  }
  .footer-view > div:first-child,
  .footer-view > div:nth-child(3) {
    flex-direction: column;
    gap: 16px;
  }
}

.footer-view > div:last-child > ul:last-child {
  padding: 0;
}

.footer-view-navigation {
  list-style: none;
}

.socials {
  list-style: none;
  list-style-type: none;
}

.socials > li {
  display: inline-block;
}

.socials > li > a > img {
  cursor: pointer;
  width: 20px;
  height: 20px;
}

.footer-menu-item {
  color: rgba(250, 250, 250, 1);
  cursor: pointer;
  font-family: inherit;
  font-weight: 300;
  display: block;
  position: relative;
  padding-top: 16px;
}

.footer-menu-item::after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 1px;
  bottom: 0;
  left: 0;
  background-color: #ffffff;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.footer-menu-item:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.footer-bottom {
  grid-column: 1/4;
  position: relative;
  gap: 50px;
  height: fit-content;
}

.footer-bottom > p {
  font-size: 10px;
  color: rgb(250, 250, 250);
  font-family: inherit;
}

.footer-bottom::before {
  content: ''; /* Required for the pseudo-element to work */
  position: absolute;
  top: 0;
  left: 10%; /* Adjust this value to increase/decrease the clear space on the left */
  right: 10%; /* Adjust this value to increase/decrease the clear space on the right */
  height: 1px; /* Thickness of the border */
  background-color: rgba(250, 250, 250, 0.5);
}
